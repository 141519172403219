var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.results && !_vm.resultsLoading
        ? _c(
            "v-card",
            [
              !_vm.subjectLine
                ? [
                    _c("line-chart", {
                      attrs: {
                        "chart-data": _vm.chartData,
                        options: _vm.getChartOptionsLinePercent(),
                        height: 400,
                        width: 500
                      }
                    })
                  ]
                : _vm._e(),
              _vm.criteria === 1
                ? [
                    _c("v-data-table", {
                      attrs: {
                        "item-key": "campaignId",
                        headers: _vm.campaignHeaders,
                        items: _vm.campaigns,
                        expanded: _vm.expanded,
                        "fixed-header": "",
                        dense: "",
                        "show-expand": "",
                        "hide-default-footer": "",
                        "disable-sort": "",
                        "disable-pagination": "",
                        "disable-filtering": "",
                        height: "320px"
                      },
                      on: {
                        "update:expanded": function($event) {
                          _vm.expanded = $event
                        },
                        "click:row": _vm.toggleExpandRow
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "expanded-item",
                            fn: function(ref) {
                              var headers = ref.headers
                              var item = ref.item
                              return [
                                _c(
                                  "td",
                                  {
                                    staticClass: "px-0",
                                    attrs: { colspan: headers.length }
                                  },
                                  [
                                    _c("CampaignDashboardExpanded", {
                                      attrs: { campaign: item }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "item.rank",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.campaigns
                                          .map(function(x) {
                                            return x.campaignId
                                          })
                                          .indexOf(item.campaignId) + 1
                                      )
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "item.value",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("span", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.metricValues[
                                          _vm.campaigns
                                            .map(function(x) {
                                              return x.campaignId
                                            })
                                            .indexOf(item.campaignId)
                                        ]
                                      ) +
                                      " " +
                                      _vm._s(_vm.append) +
                                      "\n          "
                                  )
                                ])
                              ]
                            }
                          },
                          {
                            key: "item.campaignName",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                item.campaignName &&
                                item.campaignName.length > 100
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "", "max-width": "500" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "span",
                                                    _vm._g(
                                                      _vm._b(
                                                        {},
                                                        "span",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("truncate")(
                                                            item.campaignName,
                                                            100
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.campaignName))
                                        ])
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(item.campaignName))
                                    ])
                              ]
                            }
                          },
                          {
                            key: "item.scheduleDate",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                item.broadcastScheduleDate
                                  ? _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          color: _vm.$_dashMixin_scheduleDateColor(
                                            _vm.$_dashMixin_dateToLocal(
                                              item.broadcastScheduleDate
                                            ),
                                            item.mailings.find(function(x) {
                                              return x.sequence === 1
                                            }),
                                            item
                                          ),
                                          dark: ""
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm._f("displayDateTimeFormat")(
                                                _vm.$_dashMixin_dateToLocal(
                                                  item.broadcastScheduleDate
                                                )
                                              )
                                            ) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      (item.mailings.find(function(x) {
                                        return x.sequence === 1
                                      }).winningDistribution > 0 ||
                                        item.mailings.length > 1) &&
                                        item.lastScheduleDate &&
                                        item.broadcastScheduleDate !==
                                          item.lastScheduleDate
                                        ? " - "
                                        : ""
                                    ) +
                                    "\n          "
                                ),
                                (item.mailings.find(function(x) {
                                  return x.sequence === 1
                                }).winningDistribution > 0 ||
                                  item.mailings.length > 1) &&
                                item.lastScheduleDate &&
                                item.broadcastScheduleDate !==
                                  item.lastScheduleDate
                                  ? _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          color: _vm.$_dashMixin_scheduleDateColorWinner(
                                            _vm.$_dashMixin_dateToLocal(
                                              item.lastScheduleDate
                                            ),
                                            item.mailings.find(function(x) {
                                              return (
                                                x.id === item.lastScheduledId
                                              )
                                            }),
                                            item
                                          ),
                                          dark: ""
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm._f("displayDateTimeFormat")(
                                                _vm.$_dashMixin_dateToLocal(
                                                  item.lastScheduleDate
                                                )
                                              )
                                            ) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "item.mailings",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm._l(item.mailings, function(mailing) {
                                  return _c(
                                    "v-tooltip",
                                    {
                                      key: mailing.id,
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        color: _vm.$_dashMixin_getMailingIconColor(
                                                          mailing
                                                        ),
                                                        left: ""
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.$_dashMixin_getMailingIcon(
                                                            mailing
                                                          )
                                                        ) +
                                                        "\n              "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(mailing.statusDisplay))
                                      ])
                                    ]
                                  )
                                }),
                                item.numberOfBroadcasts >=
                                  item.mailings.length && !item.campaignExpired
                                  ? _vm._l(
                                      item.numberOfBroadcasts -
                                        item.mailings.length,
                                      function(i) {
                                        return _c(
                                          "v-tooltip",
                                          {
                                            key: i,
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              color: "grey",
                                                              left: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                  mdi-email-outline\n                "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [_c("span", [_vm._v("Not Created")])]
                                        )
                                      }
                                    )
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "item.actions",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                item.campaignStatus ===
                                  _vm.$_campaignStatus_Completed &&
                                item.campaignFinished &&
                                (_vm.$_global_hasRouteClaims(
                                  "CampaignEventsHeatmap"
                                ) ||
                                  _vm.$_global_hasRouteClaims("CampaignReport"))
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var onTooltip = ref.on
                                                return [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: { "offset-y": "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var onMenu =
                                                                ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "mr-2",
                                                                        attrs: {
                                                                          color:
                                                                            "info"
                                                                        }
                                                                      },
                                                                      "v-icon",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    Object.assign(
                                                                      {},
                                                                      onTooltip,
                                                                      onMenu
                                                                    )
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "\n                    mdi-chart-box\n                  "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c(
                                                        "v-list",
                                                        [
                                                          _vm.$_global_hasRouteClaims(
                                                            "CampaignEventsHeatmap"
                                                          ) &&
                                                          _vm.$_dashMixin_isGeoDataFileTypeId(
                                                            item.targetAudienceFileTypeId
                                                          )
                                                            ? _c(
                                                                "v-list-item",
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _c(
                                                                        "router-link",
                                                                        {
                                                                          attrs: {
                                                                            to: {
                                                                              name:
                                                                                "CampaignEventsHeatmap",
                                                                              params: {
                                                                                campaignId:
                                                                                  item.campaignId
                                                                              }
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        Campaign Heatmap\n                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.$_global_hasRouteClaims(
                                                            "CampaignReport"
                                                          )
                                                            ? _c(
                                                                "v-list-item",
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _c(
                                                                        "router-link",
                                                                        {
                                                                          attrs: {
                                                                            to: {
                                                                              name:
                                                                                "CampaignReport",
                                                                              params: {
                                                                                campaignId:
                                                                                  item.campaignId
                                                                              }
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        Campaign Report\n                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [_c("span", [_vm._v("Reporting")])]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                : [
                    _vm.subjectLine
                      ? [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "4", lg: "4" } },
                                [
                                  _c("v-subheader", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.chartTitle +
                                            " " +
                                            (this.isTop ? "Top" : "Bottom")
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex pt-3 pr-4",
                                  staticStyle: { "justify-content": "end" },
                                  attrs: { cols: "12", sm: "4", lg: "3" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { left: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var onTooltip = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        color: "white"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.$_downloadFileMixin_downloadDataTableReport(
                                                            "" + _vm.tableId,
                                                            _vm.slTableTitle +
                                                              " " +
                                                              (_vm.isTop
                                                                ? "Top"
                                                                : "Bottom") +
                                                              "-Performers " +
                                                              _vm.dates
                                                          )
                                                        }
                                                      }
                                                    },
                                                    onTooltip
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color:
                                                            "green lighten-3"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    mdi-file-download\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1063353445
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Download " +
                                            _vm._s(
                                              _vm.chartTitle +
                                                " " +
                                                (this.isTop ? "top" : "bottom")
                                            ) +
                                            " performers report"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _c("v-data-table", {
                      attrs: {
                        id: _vm.tableId,
                        "item-key": "id",
                        headers: _vm.subjectLine
                          ? _vm.mailingSubjectLineHeaders
                          : _vm.mailingHeaders,
                        items: _vm.mailingItems,
                        "fixed-header": "",
                        dense: "",
                        "hide-default-footer": "",
                        "disable-sort": "",
                        "disable-pagination": "",
                        "disable-filtering": "",
                        height: "320px"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.rank",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.mailingItems
                                          .map(function(x) {
                                            return x.id
                                          })
                                          .indexOf(item.id) + 1
                                      )
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "item.value",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("span", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.metricValues[
                                          _vm.mailingItems
                                            .map(function(x) {
                                              return x.id
                                            })
                                            .indexOf(item.id)
                                        ]
                                      ) +
                                      " " +
                                      _vm._s(_vm.append) +
                                      "\n          "
                                  )
                                ])
                              ]
                            }
                          },
                          {
                            key: "item.name",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item.campaignName + " - " + item.name
                                    )
                                  )
                                ])
                              ]
                            }
                          },
                          {
                            key: "item.splits",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                item.splits && item.splits.length === 1
                                  ? [
                                      _c("td", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(item.splits[0].subject) +
                                            "\n            "
                                        )
                                      ])
                                    ]
                                  : _vm._l(item.splits, function(split, i) {
                                      return _c(
                                        "v-tooltip",
                                        {
                                          key: i,
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-progress-linear",
                                                      _vm._g(
                                                        {
                                                          style:
                                                            "width: " +
                                                            split.distribution +
                                                            "%",
                                                          attrs: {
                                                            striped: "",
                                                            height: "20",
                                                            value: "100",
                                                            color:
                                                              _vm
                                                                .$_splitMixin_splitColors[
                                                                i
                                                              ]
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var value =
                                                                    ref.value
                                                                  return [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            split.distribution
                                                                          ) +
                                                                            "%"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                split.subject ||
                                                  "Subject " +
                                                    _vm
                                                      .$_splitMixin_splitLetters[
                                                      i
                                                    ]
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    }),
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-progress-linear",
                                                  _vm._g(
                                                    {
                                                      style:
                                                        "width: " +
                                                        item.winningDistribution +
                                                        "%",
                                                      attrs: {
                                                        striped: "",
                                                        height: "20",
                                                        "buffer-value": "100",
                                                        color:
                                                          _vm.$_splitMixin_winningColor
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var value =
                                                                ref.value
                                                              return [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.winningDistribution
                                                                    ) + "%"
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$_splitMixin_winningCriteriaSelect.find(
                                              function(x) {
                                                return (
                                                  x.value ===
                                                  item.winningCriteria
                                                )
                                              }
                                            )
                                              ? _vm.$_splitMixin_winningCriteriaSelect.find(
                                                  function(x) {
                                                    return (
                                                      x.value ===
                                                      item.winningCriteria
                                                    )
                                                  }
                                                ).text
                                              : "TBD"
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              ]
                            }
                          },
                          {
                            key: "item.azHtmlContentLink",
                            fn: function(ref) {
                              var item = ref.item
                              return _vm._l(item.splits, function(split, i) {
                                return _c(
                                  "v-btn",
                                  {
                                    key: i,
                                    attrs: {
                                      icon: "",
                                      color: _vm.$_splitMixin_splitColors[i]
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.getSplitHtmlDialog(
                                          split.id,
                                          item.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "title" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$_splitMixin_indexToChar(i)
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ]
                                )
                              })
                            }
                          },
                          !_vm.subjectLine
                            ? {
                                key: "item.scheduleDate",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    item.scheduleDate && item.isScheduledStatus
                                      ? _c(
                                          "v-chip",
                                          {
                                            attrs: {
                                              color: _vm.$_dashMixin_scheduleDateColor(
                                                _vm.$_dashMixin_dateToLocal(
                                                  item.scheduleDate
                                                ),
                                                item,
                                                null
                                              ),
                                              dark: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n            " +
                                                _vm._s(
                                                  _vm._f(
                                                    "displayDateTimeFormat"
                                                  )(
                                                    _vm.$_dashMixin_dateToLocal(
                                                      item.scheduleDate
                                                    )
                                                  )
                                                ) +
                                                "\n          "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(
                                          item.winningDistribution > 0 &&
                                            item.winningScheduleDate &&
                                            item.isScheduledStatus
                                            ? " / "
                                            : ""
                                        ) +
                                        "\n          "
                                    ),
                                    item.winningDistribution > 0 &&
                                    item.winningScheduleDate &&
                                    item.isScheduledStatus
                                      ? _c(
                                          "v-chip",
                                          {
                                            attrs: {
                                              color: _vm.$_dashMixin_scheduleDateColorWinner(
                                                _vm.$_dashMixin_dateToLocal(
                                                  item.winningScheduleDate
                                                ),
                                                item,
                                                null
                                              ),
                                              dark: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n            " +
                                                _vm._s(
                                                  _vm._f(
                                                    "displayDateTimeFormat"
                                                  )(
                                                    _vm.$_dashMixin_dateToLocal(
                                                      item.winningScheduleDate
                                                    )
                                                  )
                                                ) +
                                                "\n          "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            : null,
                          !_vm.subjectLine
                            ? {
                                key: "item.actions",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm.$_global_hasRouteClaims(
                                      "MailingResultsDashboard"
                                    ) ||
                                    (item.status ===
                                      _vm.$_mailingItemStatus_Sent &&
                                      _vm.$_global_hasRouteClaims(
                                        "SingleMailingReport"
                                      ))
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var onTooltip = ref.on
                                                    return [
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          attrs: {
                                                            "offset-y": ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var onMenu =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "mr-2",
                                                                            attrs: {
                                                                              color:
                                                                                "info"
                                                                            }
                                                                          },
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        Object.assign(
                                                                          {},
                                                                          onTooltip,
                                                                          onMenu
                                                                        )
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "\n                    mdi-chart-box\n                  "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "v-list",
                                                            [
                                                              _vm.$_global_hasRouteClaims(
                                                                "MailingResultsDashboard"
                                                              )
                                                                ? _c(
                                                                    "v-list-item",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _c(
                                                                            "router-link",
                                                                            {
                                                                              attrs: {
                                                                                to: {
                                                                                  name:
                                                                                    "MailingResultsDashboard",
                                                                                  params: {
                                                                                    campaignId:
                                                                                      item.campaignId,
                                                                                    mailingItemId:
                                                                                      item.id
                                                                                  }
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                        Real Time Results\n                      "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              item.status ===
                                                                _vm.$_mailingItemStatus_Sent &&
                                                              _vm.$_global_hasRouteClaims(
                                                                "SingleMailingReport"
                                                              )
                                                                ? _c(
                                                                    "v-list-item",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _c(
                                                                            "router-link",
                                                                            {
                                                                              attrs: {
                                                                                to: {
                                                                                  name:
                                                                                    "SingleMailingReport",
                                                                                  params: {
                                                                                    campaignId:
                                                                                      item.campaignId,
                                                                                    mailingItemId:
                                                                                      item.id
                                                                                  }
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                        Single Mailing Report\n                      "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [_c("span", [_vm._v("Reporting")])]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            : null
                        ],
                        null,
                        true
                      )
                    }),
                    _c(
                      "v-dialog",
                      {
                        attrs: { scrollable: "", "max-width": "1400" },
                        model: {
                          value: _vm.htmlDialog,
                          callback: function($$v) {
                            _vm.htmlDialog = $$v
                          },
                          expression: "htmlDialog"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-text",
                              {
                                staticClass: "pa-5",
                                staticStyle: { "max-height": "800px" }
                              },
                              [
                                _c("MailingPreview", {
                                  attrs: {
                                    "mailing-item-id": _vm.mailingItemId,
                                    "split-id": _vm.splitId
                                  }
                                })
                              ],
                              1
                            ),
                            _c("v-divider"),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "tertiary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.closeHtmlDialog()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Close\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
            ],
            2
          )
        : _vm.resultsLoading
        ? _c("v-skeleton-loader", { attrs: { type: "image@2" } })
        : _c(
            "v-card",
            [_c("v-card-text", [_vm._v("\n      No data\n    ")])],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }