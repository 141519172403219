<template>
  <div>
    <v-card v-if="results && !resultsLoading">
      <template v-if="!subjectLine">
        <line-chart
          :chart-data="chartData"
          :options="getChartOptionsLinePercent()"
          :height="400"
          :width="500"
        />
      </template>
      <template v-if="criteria === 1">
        <v-data-table
          item-key="campaignId"
          :headers="campaignHeaders"
          :items="campaigns"
          :expanded.sync="expanded"
          fixed-header
          dense
          show-expand
          hide-default-footer
          disable-sort
          disable-pagination
          disable-filtering
          height="320px"
          @click:row="toggleExpandRow"
        >
          <template v-slot:expanded-item="{ headers, item}">
            <td
              :colspan="headers.length"
              class="px-0"
            >
              <CampaignDashboardExpanded
                :campaign="item"
              />
            </td>
          </template>
          <template v-slot:[`item.rank`]="{ item }">
            <span class="font-weight-bold">{{ campaigns.map(function(x) {return x.campaignId; }).indexOf(item.campaignId) + 1 }}</span>
          </template>
          <template v-slot:[`item.value`]="{ item }">
            <span>
              {{ metricValues[campaigns.map(function(x) {return x.campaignId; }).indexOf(item.campaignId)] }} {{ append }}
            </span>
          </template>
          <template v-slot:[`item.campaignName`]="{ item }">
            <v-tooltip
              v-if="item.campaignName && item.campaignName.length > 100"
              top
              max-width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >{{ item.campaignName | truncate(100) }}</span>
              </template>
              <span>{{ item.campaignName }}</span>
            </v-tooltip>
            <span v-else>{{ item.campaignName }}</span>
          </template>
          <template v-slot:[`item.scheduleDate`]="{ item }">
            <v-chip
              v-if="item.broadcastScheduleDate"
              :color="$_dashMixin_scheduleDateColor($_dashMixin_dateToLocal(item.broadcastScheduleDate), item.mailings.find(x => x.sequence === 1), item)"
              dark
            >
              {{ $_dashMixin_dateToLocal(item.broadcastScheduleDate) | displayDateTimeFormat }}
            </v-chip>
            {{ (item.mailings.find(x => x.sequence === 1).winningDistribution > 0 || item.mailings.length > 1) && item.lastScheduleDate && item.broadcastScheduleDate !== item.lastScheduleDate ? ' - ' : '' }}
            <v-chip
              v-if="(item.mailings.find(x => x.sequence === 1).winningDistribution > 0 || item.mailings.length > 1) && item.lastScheduleDate && item.broadcastScheduleDate !== item.lastScheduleDate"
              :color="$_dashMixin_scheduleDateColorWinner($_dashMixin_dateToLocal(item.lastScheduleDate), item.mailings.find(x => x.id === item.lastScheduledId), item)"
              dark
            >
              {{ $_dashMixin_dateToLocal(item.lastScheduleDate) | displayDateTimeFormat }}
            </v-chip>
          </template>
          <template v-slot:[`item.mailings`]="{ item }">
            <v-tooltip
              v-for="(mailing) in item.mailings"
              :key="mailing.id"
              top
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  :color="$_dashMixin_getMailingIconColor(mailing)"
                  left
                  v-on="on"
                >
                  {{ $_dashMixin_getMailingIcon(mailing) }}
                </v-icon>
              </template>
              <span>{{ mailing.statusDisplay }}</span>
            </v-tooltip>
            <template v-if="item.numberOfBroadcasts >= item.mailings.length && !item.campaignExpired">
              <v-tooltip
                v-for="i in item.numberOfBroadcasts - item.mailings.length"
                :key="i"
                top
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    color="grey"
                    left
                    v-on="on"
                  >
                    mdi-email-outline
                  </v-icon>
                </template>
                <span>Not Created</span>
              </v-tooltip>
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip
              v-if="item.campaignStatus === $_campaignStatus_Completed && item.campaignFinished && ($_global_hasRouteClaims('CampaignEventsHeatmap') || $_global_hasRouteClaims('CampaignReport'))"
              top
            >
              <template v-slot:activator="{ on: onTooltip }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on: onMenu, attrs }">
                    <v-icon
                      class="mr-2"
                      color="info"
                      v-bind="attrs"
                      v-on="{ ...onTooltip, ...onMenu }"
                    >
                      mdi-chart-box
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-if="$_global_hasRouteClaims('CampaignEventsHeatmap') && $_dashMixin_isGeoDataFileTypeId(item.targetAudienceFileTypeId)">
                      <v-list-item-title>
                        <router-link :to="{ name: 'CampaignEventsHeatmap', params: { campaignId: item.campaignId } }">
                          Campaign Heatmap
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="$_global_hasRouteClaims('CampaignReport')">
                      <v-list-item-title>
                        <router-link :to="{ name: 'CampaignReport', params: { campaignId: item.campaignId } }">
                          Campaign Report
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <span>Reporting</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <template v-if="subjectLine">
          <v-row>
            <v-col
              cols="12"
              sm="4"
              lg="4"
            >
              <v-subheader>
                {{ `${chartTitle} ${this.isTop ? 'Top' : 'Bottom'}` }}
              </v-subheader>
            </v-col>
            <v-spacer />
            <v-col
              class="d-flex pt-3 pr-4"
              style="justify-content: end"
              cols="12"
              sm="4"
              lg="3"
            >
              <v-tooltip
                left
              >
                <template v-slot:activator="{ on: onTooltip }">
                  <v-btn
                    icon
                    color="white"
                    v-on="onTooltip"
                    @click="$_downloadFileMixin_downloadDataTableReport(`${tableId}`, `${slTableTitle} ${isTop ? 'Top' : 'Bottom'}-Performers ${dates}`)"
                  >
                    <v-icon color="green lighten-3">
                      mdi-file-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>Download {{ `${chartTitle} ${this.isTop ? 'top' : 'bottom'}` }} performers report</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
        <v-data-table
          :id="tableId"
          item-key="id"
          :headers="subjectLine ? mailingSubjectLineHeaders : mailingHeaders"
          :items="mailingItems"
          fixed-header
          dense
          hide-default-footer
          disable-sort
          disable-pagination
          disable-filtering
          height="320px"
        >
          <template v-slot:[`item.rank`]="{ item }">
            <span class="font-weight-bold">{{ mailingItems.map(function(x) {return x.id; }).indexOf(item.id) + 1 }}</span>
          </template>
          <template v-slot:[`item.value`]="{ item }">
            <span>
              {{ metricValues[mailingItems.map(function(x) {return x.id; }).indexOf(item.id)] }} {{ append }}
            </span>
          </template>
          <template
            v-slot:[`item.name`]="{ item }"
          >
            <span>{{ `${item.campaignName} - ${item.name}` }}</span>
          </template>
          <template v-slot:[`item.splits`]="{ item }">
            <template v-if="item.splits && item.splits.length === 1">
              <td>
                {{ item.splits[0].subject }}
              </td>
            </template>
            <template v-else>
              <v-tooltip
                v-for="(split, i) in item.splits"
                :key="i"
                top
              >
                <template v-slot:activator="{ on }">
                  <v-progress-linear
                    :style="`width: ${split.distribution}%`"
                    striped
                    height="20"
                    value="100"
                    :color="$_splitMixin_splitColors[i]"
                    v-on="on"
                  >
                    <template v-slot="{ value }">
                      <strong>{{ split.distribution }}%</strong>
                    </template>
                  </v-progress-linear>
                </template>
                <span>{{ split.subject || `Subject ${$_splitMixin_splitLetters[i]}` }}</span>
              </v-tooltip>
            </template>
            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-progress-linear
                    :style="`width: ${item.winningDistribution}%`"
                    striped
                    height="20"
                    buffer-value="100"
                    :color="$_splitMixin_winningColor"
                    v-on="on"
                  >
                    <template v-slot="{ value }">
                      <strong>{{ item.winningDistribution }}%</strong>
                    </template>
                  </v-progress-linear>
                </template>
                <span>{{ $_splitMixin_winningCriteriaSelect.find(x => x.value === item.winningCriteria) ? $_splitMixin_winningCriteriaSelect.find(x => x.value === item.winningCriteria).text : 'TBD' }}</span>
              </v-tooltip>
            </template>
          </template>
          <template v-slot:[`item.azHtmlContentLink`]="{ item }">
            <v-btn
              v-for="(split, i) in item.splits"
              :key="i"
              icon
              :color="$_splitMixin_splitColors[i]"
              @click="getSplitHtmlDialog(split.id, item.id)"
            >
              <span
                class="title"
              >
                {{ $_splitMixin_indexToChar(i) }}
              </span>
            </v-btn>
          </template>
          <template
            v-if="!subjectLine"
            v-slot:[`item.scheduleDate`]="{ item }"
          >
            <v-chip
              v-if="item.scheduleDate && item.isScheduledStatus"
              :color="$_dashMixin_scheduleDateColor($_dashMixin_dateToLocal(item.scheduleDate), item, null)"
              dark
            >
              {{ $_dashMixin_dateToLocal(item.scheduleDate) | displayDateTimeFormat }}
            </v-chip>
            {{ item.winningDistribution > 0 && item.winningScheduleDate && item.isScheduledStatus ? ' / ' : '' }}
            <v-chip
              v-if="item.winningDistribution > 0 && item.winningScheduleDate && item.isScheduledStatus"
              :color="$_dashMixin_scheduleDateColorWinner($_dashMixin_dateToLocal(item.winningScheduleDate), item, null)"
              dark
            >
              {{ $_dashMixin_dateToLocal(item.winningScheduleDate) | displayDateTimeFormat }}
            </v-chip>
          </template>
          <template
            v-if="!subjectLine"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-tooltip
              v-if="$_global_hasRouteClaims('MailingResultsDashboard') || (item.status === $_mailingItemStatus_Sent && $_global_hasRouteClaims('SingleMailingReport'))"
              top
            >
              <template v-slot:activator="{ on: onTooltip }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on: onMenu, attrs }">
                    <v-icon
                      class="mr-2"
                      color="info"
                      v-bind="attrs"
                      v-on="{ ...onTooltip, ...onMenu }"
                    >
                      mdi-chart-box
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-if="$_global_hasRouteClaims('MailingResultsDashboard')">
                      <v-list-item-title>
                        <router-link :to="{ name: 'MailingResultsDashboard', params: { campaignId: item.campaignId, mailingItemId: item.id } }">
                          Real Time Results
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="item.status === $_mailingItemStatus_Sent && $_global_hasRouteClaims('SingleMailingReport')">
                      <v-list-item-title>
                        <router-link :to="{ name: 'SingleMailingReport', params: { campaignId: item.campaignId, mailingItemId: item.id } }">
                          Single Mailing Report
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <span>Reporting</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-dialog
          v-model="htmlDialog"
          scrollable
          max-width="1400"
        >
          <v-card>
            <v-card-text
              class="pa-5"
              style="max-height: 800px"
            >
              <MailingPreview
                :mailing-item-id="mailingItemId"
                :split-id="splitId"
              />
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="tertiary"
                @click="closeHtmlDialog()"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-card>
    <v-skeleton-loader
      v-else-if="resultsLoading"
      type="image@2"
    />
    <v-card v-else>
      <v-card-text>
        No data
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  inputDateFormat
} from '@/shared/constants'
import { mailingService } from '@/shared/services'
import moment from 'moment'
import { splitMixin, dashboardMixin } from '@/shared/mixins/mailing'
import { downloadFileMixin } from '@/shared/mixins/general'
import mutationTypes from '@/store/mutation-types'

export default {
  name: 'TopPerformersChartLogic',

  components: {
    LineChart: () => import('@/views/pages/components/charts/LineChart'),
    CampaignDashboardExpanded: () => import('@/views/pages/components/mailing/CampaignDashboardExpanded'),
    MailingPreview: () => import('@/views/pages/components/mailing/MailingPreview')
  },

  mixins: [splitMixin, dashboardMixin, downloadFileMixin],

  props: {
    dates: {
      type: Array,
      default: () => []
    },
    criteria: {
      type: Number,
      default: 1
    },
    subjectLine: {
      type: Boolean,
      dafault: false
    },
    itemCount: {
      type: Number,
      default: 10
    },
    isTop: {
      type: Boolean,
      default: true
    },
    metric: {
      type: String,
      default: 'openRate'
    },
    chartTitle: {
      type: String,
      default: 'Open Rate'
    },
    slTableTitle: {
      type: String,
      default: 'Open Rate'
    },
    append: {
      type: String,
      default: '%'
    },
    precision: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      results: [],
      resultsLoading: false,
      expanded: [],
      htmlDialog: false,
      splitId: '',
      mailingItemId: '',
      mailingHeaders: [
        {
          text: '#',
          value: 'rank',
          width: 25
        },
        {
          text: `${this.chartTitle} ${this.append}`,
          value: 'value',
          width: 100
        },
        {
          text: 'Mailing Name',
          value: 'name',
          width: '250'
        },
        {
          text: 'Subject',
          value: 'splits',
          width: '400'
        },
        {
          text: 'Details',
          value: 'azHtmlContentLink',
          width: '175'
        },
        {
          text: 'Schedule Date(s)',
          value: 'scheduleDate',
          width: '180'
        },
        {
          text: 'Actions',
          value: 'actions',
          width: '50'
        }
      ],
      campaignHeaders: [
        {
          text: '#',
          value: 'rank',
          width: 25
        },
        {
          text: `${this.chartTitle} ${this.append}`,
          value: 'value',
          width: 100
        },
        {
          text: 'Campaign Name',
          value: 'campaignName',
          width: '200'
        },
        {
          text: 'Schedule Date(s)',
          value: 'scheduleDate',
          width: '180'
        },
        {
          text: 'Mailings',
          value: 'mailings',
          width: '160'
        },
        {
          text: 'Actions',
          value: 'actions',
          width: '75'
        }
      ],
      mailingSubjectLineHeaders: [
        {
          text: '#',
          value: 'rank',
          width: 25
        },
        {
          text: `${this.chartTitle} ${this.append}`,
          value: 'value',
          width: 100
        },
        {
          text: 'Mailing Name',
          value: 'name',
          width: '250'
        },
        {
          text: 'Subject',
          value: 'splits',
          width: '400'
        },
        {
          text: 'Details',
          value: 'azHtmlContentLink',
          width: '175'
        }
      ],
      unsubscribe: null
    }
  },

  computed: {
    criteriaDisplay () {
      switch (this.criteria) {
        case 1:
          return 'Campaign'
        case 2:
          return 'Mailing'
        default:
          return 'Campaign'
      }
    },
    campaigns () {
      if (!this.results) return []
      // return this.results.map(x => x.campaign).slice(0, (this.itemCount))
      return this.results.map(x => (
        {
          ...x.campaign,
          targetAudience: {
            ...x.campaign.targetAudience,
            orderDateLocal: x.campaign.targetAudience && x.campaign.targetAudience.orderDate ? moment(this.$_dashMixin_dateToLocal(x.campaign.targetAudience.orderDate)) : null
          }
        }
      )).slice(0, (this.itemCount))
    },
    mailingItems () {
      if (!this.results) return []
      return this.results.map(x => x.mailingItem).slice(0, (this.itemCount))
    },
    metricValues () {
      if (!this.results) return []
      return this.results.map(item => item[this.metric])
    },
    sortLabel () {
      return this.isTop ? 'Top' : 'Bottom'
    },
    sortColor () {
      return this.isTop ? '#A5D6A7' : '#E57373'
    },
    tableId () {
      switch (this.isTop) {
        case true:
          return `${this.metric}Top`
        case false:
          return `${this.metric}Bottom`
        default:
          return `${this.metric}Top`
      }
    },
    chartData () {
      return {
        labels: this.criteria === 1 ? this.results.map(item => item.campaign.campaignName).slice(0, (this.itemCount))
          : this.results.map(item => `${item.mailingItem.campaignName} - ${item.mailingItem.name}`).slice(0, (this.itemCount)),
        datasets: [
          {
            data: this.results.map(item => item[this.metric]),
            label: `${this.sortLabel} ${Math.min(this.itemCount, this.results.length)} Performers`,
            borderColor: this.sortColor,
            fill: false
          }
        ]
      }
    }
  },

  watch: {
    dates (newValue, oldValue) {
      if (newValue.length === 2) {
        this.getTopPerformers()
      }
    },
    criteria (newValue, oldValue) {
      this.getTopPerformers()
    }
  },

  created () {
    this.getTopPerformers()
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type)) {
        this.getTopPerformers()
      }
    })
  },

  destroyed () {
    this.unsubscribe()
  },

  methods: {
    async getTopPerformers () {
      if (this.dates.length !== 2 || this.$store.getters['isSimulatedMMS']()) return
      this.results = []
      this.resultsLoading = true
      await mailingService.getTopPerformers({
        customerId: this.$store.getters['simulatedCustomerNowwId'](),
        startDate: moment(this.dates[0]).startOf('month').format(inputDateFormat),
        endDate: moment(this.dates[1]).endOf('month').format(inputDateFormat),
        criteria: this.criteria,
        metric: this.metric,
        isTop: this.isTop,
        topBottomCount: 20
      })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.results = resp
          }
        })
        .finally(() => {
          this.resultsLoading = false
        })
    },
    async getSplitHtmlDialog (id, mailingItemId) {
      this.htmlDialog = true
      this.splitId = id
      this.mailingItemId = mailingItemId
    },

    closeHtmlDialog () {
      this.htmlDialog = false
      this.splitId = ''
      this.mailingItemId = ''
    },
    toggleExpandRow (value) {
      if (!value.mailings || value.mailings.length === 0) return
      if (this.expanded.includes(value)) {
        value._expandedIcon = 'down'
        this.expanded.splice(this.expanded.indexOf(value), 1)
      } else {
        value._expandedIcon = 'up'
        this.expanded.push(value)
      }
    },
    getChartOptionsLinePercent () {
      var _this = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
          fontStyle: 'bold',
          fontSize: 18,
          text: _this.chartTitle
        },
        legend: {
          labels: {
            fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
            fontSize: 14
          }
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14
              // labelString: yAxisLabel
            },
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              // Include a '%' sign in the ticks
              callback: function (value, index, values) {
                return `${value}${_this.append}`
              }
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
              fontStyle: 'bold',
              fontSize: 14,
              labelString: `${_this.criteriaDisplay} Name`
            },
            ticks: {
              fontFamily: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'"
            }
          }]
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItem, data) => {
              return `${tooltipItem.value}${_this.append}`
            }
          }
        }
        // plugins: {
        //   datalabels: {
        //     clamp: true,
        //     anchor: 'end',
        //     align: 'top',
        //     rotation: -30,
        //     formatter: function (value, context) {
        //       return `${parseFloat(value).toFixed(_this.precision)}${_this.append}`
        //     },
        //     font: {
        //       family: "'Century Gothic','CenturyGothic','AppleGothic','Roboto','sans-serif'",
        //       weight: 'bold'
        //     }
        //   }
        // }
      }
    }
  }
}
</script>
<style>
.v-progress-linear {
  display: inline-block;
  vertical-align: middle;
}
</style>
